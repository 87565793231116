.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.login-info {
  display: flex;
  justify-content: space-between;
}
