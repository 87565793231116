.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

form {
  display: flex;
}

#input-message {
  flex-grow: 1;
  font-size: 1.2rem;
}

label {
  padding-right: 5px;
}

.chat-area {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  width: 100vw;
  max-width: 700px;
}

.container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .chat-area {
    width: 60%;
  }
}
